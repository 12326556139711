import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <nav className="nav">
      <div>
        <Link to="/">
          <div
            className="nav-logo"
            onClick={() => scrollToSection("header")}
          ></div>
        </Link>
      </div>
      <ul
        className={`nav-menu ${showMobileMenu ? "active" : ""}`}
        onClick={closeMobileMenu}
      >
        <li className="nav-menu-item">
          <Link to="/" onClick={() => scrollToSection("header")}>
            Strona główna
          </Link>
        </li>
        <li className="nav-menu-item">
          <Link onClick={() => scrollToSection(props.aboutTo)}>
            {props.about}
          </Link>
        </li>
        <li className="nav-menu-item">
          <Link onClick={() => scrollToSection(props.offertTo)}>
            {props.offert}
          </Link>
        </li>
        <li className="nav-menu-item">
          <Link onClick={() => scrollToSection(props.contactTo)}>
            {props.contact}
          </Link>
        </li>
      </ul>
      <div className="nav-mobile-icon" onClick={toggleMobileMenu}>
        {showMobileMenu ? (
          <i className="fa-solid fa-times"></i>
        ) : (
          <i className="fa-solid fa-bars"></i>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
