import React from "react";

const Map = ({ mapUrl }) => {
  return (
    <iframe
      className="map"
      title="Google Map"
      src={mapUrl}
      style={{ border: "0" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};

export default Map;
