// App.jsx
import React from "react";
import UpperNav from "./components/UpperNav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import OffertDetails from "./pages/OffertDetails";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <UpperNav />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/oferta/:oferta" element={<OffertDetails />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
