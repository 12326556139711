import React from "react";
import MainComponentPage from "../components/MainComponentPage";

export default function Main() {
  return (
    <div>
      <MainComponentPage />
    </div>
  );
}
