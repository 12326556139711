import React from "react";

export default function Card({ image, oferta, details }) {
  return (
    <div className="cardInfo">
      <img src={image} alt={oferta} />
      <h3>{oferta}</h3>
      <p>{details}</p>
    </div>
  );
}
