import React from "react";
import { useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

export default function About(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const propsAbout = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateX(0%)" : "translateX(-100%)",
    config: config.slow,
  });

  return (
    <animated.div className="about" id="about" ref={ref} style={propsAbout}>
      <div className="aboutText">
        <h1>O nas</h1>
        <p>{props.text}</p>
        <p>
          <span> {props.span}</span>
          {props.text2}
        </p>
        {props.button}
      </div>
      <div className="aboutImage"></div>
    </animated.div>
  );
}
