import React from "react";
import About from "./About";
import Offert from "./Offert";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Copyright from "./Copyright";
import Navbar from "./Navbar";

export default function MainComponentPage() {
  const mapUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.1369882001827!2d19.864431576988725!3d51.08747934129256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4719e6cbe009ddb9%3A0x813d83f50dafb748!2sTurystyczna%208%2C%2097-570%20Przedb%C3%B3rz!5e0!3m2!1spl!2spl!4v1701341228155!5m2!1spl!2spl";
  return (
    <div className="content">
      <Navbar
        about="O nas"
        offert="Oferta"
        contact="Kontakt"
        aboutTo="about"
        offertTo="offert"
        contactTo="contact"
      />
      <Header text="Hurtownia stali " span="LUUKE" />
      <div className="box1"></div>
      <About
        text="Nasza firma specjalizuje się w dostarczaniu szerokiego zakresu wyrobów
          hutniczych, w tym blach gorącowalcowanych, rur oraz profili stalowych
          drugiego gatunku, zarówno w sprzedaży hurtowej, jak i detalicznej."
      />
      <Offert />
      <Contact
        name="LUUKE Sp. z.o.o"
        address={
          <>
            ul.Częstochowska 35 <br /> (wjazd Turystyczna 8)
          </>
        }
        city="97-570 Przedbórz"
        email="biuro@luuke.pl"
        phone="+48 603 223 269"
        phone2="+48 609 537 901"
        mapUrl={mapUrl}
      />

      <div className="box2"></div>
      <Footer />
      <Copyright />
    </div>
  );
}
