import React from "react";
import Map from "./Map";
import { useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

export default function Contact(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const propsContact = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateX(0%)" : "translateX(-100%)",
    config: config.slow,
  });

  return (
    <animated.div
      className="contact"
      id="contact"
      ref={ref}
      style={propsContact}
    >
      <div className="contactAllBox">
        <h1>Kontakt</h1>
        <div className="contactContainer">
          <div className="contactDetails">
            <div className="contactBox">
              <h4>{props.name}</h4>
              <h4>{props.address}</h4>
              <h4>{props.city}</h4>
            </div>
            <div className="contactBox">
              <h4>Dane kontaktowe</h4>
              <h4 className="phone">
                <i className="fa-regular fa-envelope"></i>
                <a href={`mailto:${props.email}`}>{props.email}</a>
              </h4>
              <h4 className="phone">
                <i className="fa-solid fa-phone"></i>
                {props.phone}
              </h4>
              <h4 className="phone">
                {props.phone2 && (
                  <>
                    <i className="fa-solid fa-phone"></i>
                    {props.phone2}
                  </>
                )}
              </h4>
            </div>
          </div>
          <animated.div className="contactBox" style={propsContact}>
            <Map mapUrl={props.mapUrl} />
          </animated.div>
        </div>
      </div>
    </animated.div>
  );
}
