import React from "react";

export default function UpperNav() {
  return (
    <div className="upperNav">
      <h4>
        <i className="far fa-envelope"></i>
        <a href="mailto:biuro@luuke.pl"> biuro@luuke.pl </a>
      </h4>
      <h4>
        <i className="fas fa-map-marker-alt"></i> ul.Częstochowska 35, 97-570
        Przedbórz
      </h4>
    </div>
  );
}
