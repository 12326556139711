// OffertDetails.jsx
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import data from "../data";
import { useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";

const OffertDetails = () => {
  const { oferta } = useParams();
  const navigate = useNavigate();

  const selectedOffert = data.find((item) => item.oferta === oferta);

  const handleBack = () => {
    // Navigate back to the previous page
    navigate(-1);
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const propsAbout = useSpring({
    opacity: inView ? 1 : 0,
    config: config.slow,
  });

  return (
    <div className="content">
      <div className="details" ref={ref}>
        <div className="btnBox">
          <button onClick={handleBack} className="btn2">
            <i className="fa-solid fa-arrow-left"></i> Cofnij
          </button>
        </div>
        <animated.div className="detailsConent" style={propsAbout}>
          <div className="imgBox">
            <img
              src={selectedOffert.moreDetailsImg}
              alt={selectedOffert.oferta}
            />
          </div>
          <div className="detailsBox">
            <h2>{selectedOffert.oferta}</h2>
            <p>{selectedOffert.moreDetails}</p>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default OffertDetails;
