const data = [
  {
    id: 1,
    oferta: "Blachy kawałkowe",
    details: "Formatowe w drugim gatunku od 3 mm do 30 mm.",
    image: "./blachy.svg",
    moreDetails:
      "Blachę formatową posiadamy o wymiarach 150/3m 2/6 150/6, i inne. Blachy kawałkowe są przeważnie od 150cm szerokości do 2 m długości większe lub mniejsze. Gatunki kawałków są od S 225 JR  do S 700 JR.",
    moreDetailsImg: "../blachKawalkowe.webp",
  },
  {
    id: 2,
    oferta: "Rury stalowe",
    details: "W pierwszym i drugim gatunku.",
    image: "./rury.svg",
    moreDetails: "Szeroki wybór rur w pierwszym i drugim gatunku.",
    moreDetailsImg: "../ruryDetal.webp",
  },
  {
    id: 3,
    oferta: "Profile stalowe zamknięte",
    image: "./metal.svg",
    moreDetailsImg: "../profileStalowe.webp",
  },
  {
    id: 4,
    oferta: "Kształtowniki gorąco wałkowane",
    details: "Teowniki, ceowniki, kątownik.",
    image: "./ksztaltownik.svg",
    moreDetails:
      "Kształtowniki gorąco wałkowane, takie jak teowniki, ceowniki oraz kątowniki.",
    moreDetailsImg: "../ksztaltownikiDetals.webp",
  },
];

export default data;
