import React from "react";

export default function Copyright() {
  const getYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="copyright">
      <p>© {getYear()} Luuke Sp. z o.o. Wszelkie prawa zastrzeżone.</p>
      <div className="codeBy">
        <p>
          Developed by{" "}
          <a href="https://mjlwebdev.pl/" target="blank">
            MJL
          </a>
        </p>
      </div>
    </div>
  );
}
