import React from "react";
import data from "../data";
import Card from "./Card";
import { useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const Offert = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const propsAbout = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateX(0%)" : "translateX(100%)",
    config: config.slow,
  });

  return (
    <div className="offert" id="offert" ref={ref}>
      <animated.div style={propsAbout}>
        <h1>Oferta</h1>
        <div className="cardBox">
          {data.map((item) => (
            <Link key={item.id} to={`/oferta/${item.oferta}`} className="card">
              <Card key={item.id} {...item} />
            </Link>
          ))}
        </div>
      </animated.div>
    </div>
  );
};

export default Offert;
