import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

export default function Header(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const location = useLocation();
  const [backgroundImage, setBackgroundImage] = useState(
    getBackgroundImage(location.pathname)
  );

  const propsAbout = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "scale(1)" : "scale(5)",
    config: config.slow,
  });

  function getBackgroundImage(pathname) {
    if (pathname === "/renpol") {
      return "url(/header2.jpg)";
    }
  }

  React.useEffect(() => {
    setBackgroundImage(getBackgroundImage(location.pathname));
  }, [location.pathname]);

  return (
    <div className="header" id="header" ref={ref} style={{ backgroundImage }}>
      {location.pathname === "/" ? (
        <video className="videoTag" autoPlay loop muted>
          <source src="/header.mp4" type="video/mp4" />
        </video>
      ) : null}
      <div className="headerContent">
        <animated.h1 style={propsAbout}>
          {props.text} <span>{props.span}</span>
        </animated.h1>
        {props.logo && (
          <img src={props.logo} alt="logo" className="logoHeader" />
        )}
      </div>
    </div>
  );
}
