import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footerBox">
        <h3>LUUKE Sp. z.o.o</h3>
        <p>ul. Częstochowska 35, 97-570 Przedbórz</p>
      </div>
      <div className="footerBox">
        <h3>Kontakt</h3>
        <p>
          {" "}
          e-mail: <a href="mailto:biuro@luuke.pl"> biuro@luuke.pl </a>
        </p>
        <p>tel.: +48 603 223 269</p>
        <p>tel.: +48 609 537 901</p>
      </div>
      <div className="footerBox">
        <h3>Godziny otwarcia</h3>
        <p>Pon-Pt: 8:00 - 16:00</p>
        <p>Sobota: 8:00 - 13:00</p>
        <p>Niedziela: nieczynne</p>
      </div>
    </div>
  );
}
